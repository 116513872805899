import React from "react"
import { Link } from "gatsby"

import useLang from "../../hooks/useLang"

import "./style.css"

const ContactMainArea = ({
  title,
  addressTitle,
  addressText,
  phone,
  phoneList,
  description,
  emailTitle,
  emailLink,
  workingHourse,
  workingHourseText,
}) => {
  const isAr = useLang() === "ar"
  const [days, hours] = workingHourseText?.split(":")
  return (
    <div className="contact">
      <div className="b-page-content map-bg">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 text-center">
              <div className="b-breadcrumbs">
                <ul className="list-inline">
                  <li>
                    <Link to={`${isAr ? "/ar" : ""}/`}>
                      {isAr ? "الرئيسية" : "home"}
                    </Link>
                  </li>
                  <li className="current">{isAr ? "الطلب" : "Order"}</li>
                </ul>
              </div>
              <h1 className="main-heading">{title}</h1>
              <div className="heading-text">
                <p style={{ maxWidth: 700, margin: "auto" }}>{description}</p>
              </div>
            </div>
            <div className="b-info-columns-holder col-xs-12 col-sm-12">
              <div className="row equal">
                <div className="b-info-column col-xs-6 col-sm-3">
                  <div className="info-column-icon">
                    <i className="fa fa-map-marker"></i>
                  </div>
                  <h6 className="info-column-title">{addressTitle}</h6>
                  <div className="info-column-text">
                    <a
                      target="_blank"
                      rel="noopner"
                      href="https://maps.app.goo.gl/wzEy94M7ham56sfF8"
                    >
                      {addressText}
                    </a>
                  </div>
                </div>
                <div className="b-info-column col-xs-6 col-sm-3">
                  <div className="info-column-icon">
                    <i className="fa fa-phone"></i>
                  </div>
                  <h6 className="info-column-title">{phone}</h6>
                  <div className="info-column-text">
                    <div>
                      {phoneList.map(item => (
                        <a
                          style={{ display: "block", direction: "ltr" }}
                          href={"tel:" + item.link}
                        >
                          {item.title}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="b-info-column col-xs-6 col-sm-3">
                  <div className="info-column-icon">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <h6 className="info-column-title">{emailTitle}</h6>
                  <div className="info-column-text">
                    <p>
                      <a href={"mailto:" + emailLink}>{emailLink}</a>
                    </p>
                  </div>
                </div>
                <div className="b-info-column col-xs-6 col-sm-3">
                  <div className="info-column-icon">
                    <i className="fa fa-clock-o"></i>
                  </div>
                  <h6 className="info-column-title">{workingHourse}</h6>
                  <div className="info-column-text">
                    <p>
                      {days} : <span>{hours}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactMainArea
