import React from "react"
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel"
import get from "lodash/get"
import debounce from "lodash/debounce"
import mapMarker from "../images/mapmarker.png"

// const YOUR_API_KEY = process.env.GATSBY_GOOGLE_API
const GOOGLE_URL = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCb49pXpxjM6OrLnWHmrxb8vRVFIM4cRB8&v=3.exp&libraries=geometry,drawing,places`

const MapComponent = compose(
  withProps({
    googleMapURL: GOOGLE_URL,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        bounds: null,
        center: this.props.location
          ? this.props.location
          : {
              lat: 24.351096,
              lng: 54.51409,
            },
        marker: {
          lat: 24.351096,
          lng: 54.51409,
        },
        onMapMounted: ref => {
          refs.map = ref
        },
        onBoundsChanged: debounce(
          () => {
            this.setState({
              bounds: refs.map.getBounds(),
              center: refs.map.getCenter(),
            })
            const { onBoundsChange } = this.props
            if (onBoundsChange) {
              onBoundsChange(refs.map)
            }
          },
          100,
          { maxWait: 500 }
        ),
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces()
          const bounds = new window.google.maps.LatLngBounds()

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          })
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }))
          const nextCenter = get(nextMarkers, "0.position", this.state.center)
          this.props.onLocationChange(nextCenter)
          this.setState({
            center: nextCenter,
            marker: nextMarkers,
          })
        },
      })
    },
    componentDidUpdate(prevProps, prevState) {
      if (this.state.marker !== prevState.marker) {
        let shit = {}
        if (
          typeof this.state.marker.lat === "function" &&
          typeof this.state.marker.lng === "function"
        ) {
          shit = {
            latitude: this.state.marker.lat(),
            longitude: this.state.marker.lng(),
          }
        } else {
          shit = {
            latitude: this.state.marker.lat,
            longitude: this.state.marker.lng,
          }
        }
        this.props.onLocationChange(shit)
      }
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const markerIcon = {
    url: mapMarker,
  }

  return (
    <GoogleMap
      ref={props.onMapMounted}
      zoom={11}
      center={props.center}
      onBoundsChanged={props.onBoundsChanged}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      <MarkerWithLabel
        position={props.marker}
        animation={window.google.maps.Animation.DROP}
        labelAnchor={new window.google.maps.Point(65, 5)}
        icon={markerIcon}
        onClick={() => window.open("https://maps.app.goo.gl/wzEy94M7ham56sfF8")}
      >
        <div />
      </MarkerWithLabel>
    </GoogleMap>
  )
})

export default MapComponent
