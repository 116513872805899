import React, { useState } from "react"
import Switch from "react-input-switch"
import { Formik, Form as FForm } from "formik"
import "./style.css"
import MapComponent from "../MapComponent"
import useLang from "../../hooks/useLang"
import Input from "../Input"
import * as Yup from "yup"

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  subject: Yup.string()
    .min(10, "Too Short!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = ({ map, form, sendButton }) => {
  const isAr = useLang() === "ar"
  const [checked, setChecked] = useState(0)

  const onSubmit = values => {
    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))
  }

  return (
    <div className="contact-page b-map-form-holder">
      <div className="w-100">
        <div className="map-form-switcher">
          <div className="switcher-bg">
            <span className="mr-4">{form}</span>
            <Switch value={checked} onChange={setChecked} />
            <span className="ml-4">{map}</span>
          </div>
        </div>
      </div>

      <div className="b-map">
        <MapComponent />
      </div>

      <div
        className="b-contact-form"
        style={{ display: checked ? "none" : "block" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 mx-auto">
              <Formik
                validationSchema={ContactSchema}
                initialValues={{ email: "", password: "" }}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, handleChange }) => (
                  <FForm
                    name="contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="contact" />

                    <div className="form-group">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 form-check-line">
                          <Input
                            onChange={handleChange}
                            name="name"
                            label={isAr ? "اسمك" : "Your Name"}
                            type="text"
                            pattern=".{5,}"
                            className="required-field namefield form-control empty-field"
                            autocomplete="off"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12 form-check-line">
                          <Input
                            type="email"
                            pattern=".{5,}"
                            className="required-field mailfield form-control empty-field"
                            name="email"
                            id="email"
                            label={isAr ? "بريدك الالكتروني" : "Your Email"}
                            autocomplete="off"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12 form-check-line">
                          <Input
                            type="text"
                            pattern=".{10,}"
                            className="required-field subfield form-control empty-field"
                            name="subject"
                            id="subject"
                            label={isAr ? "الموضوع" : "Subject"}
                            autocomplete="off"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12 form-check-line">
                          <Input
                            className="required-field textfield form-control empty-field"
                            name="message"
                            id="message"
                            rows="6"
                            type="textarea"
                            label={isAr ? "الرسالة" : "Message"}
                            autocomplete="off"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-submit text-uppercase"
                          >
                            {sendButton}
                          </button>
                        </div>
                      </div>
                    </div>
                  </FForm>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactPage
