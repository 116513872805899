import React from "react"

import Layout from "../components/layout"
import ContactMainArea from "../components/ContactMainArea"
import ContactPage from "../components/ContactPage"
import { graphql } from "gatsby"

const Contact = ({
  data: {
    markdownRemark: {
      frontmatter: { mapSection, lang, ...rest },
    },
  },
}) => (
  <Layout lang={lang} title="Contact">
    <ContactMainArea {...rest} />
    <div className="b-homepage-content">
      <ContactPage {...mapSection} />
    </div>
  </Layout>
)

export default Contact


export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        lang
        description
        addressTitle
        addressText
        phone
        phoneList
        {
          title
          link

        }
        emailTitle
        emailLink
        workingHourse
        workingHourseText
        mapSection
         {
          from
          map
          sendButton
         }
      }
    }
  }
  `